<template>
  <div>
    <app-modal @close="showModal = false; editVehicle = null" v-if="showModal">
      <add-vehicle :edit-vehicle="editVehicle" :isCompanyAdmin="isCompanyAdmin" @vehicleChanged="onVehicleChanged" :companies="companies"></add-vehicle>
    </app-modal>
    <h1 class="section-header">Fordon</h1>
    <div class="row justify-content-between mt-4">
      <div class="row col-md-8">
        <input-field v-model="search" class="col-md-3" @input="searchVehicles"  placeholder="Sök ..."></input-field>
        <v-select v-if="!isCompanyAdmin" class="col-md-4 mt-2" @input="searchVehicles" v-model="company" :reduce="item => item._id" :options="companies" label="name" placeholder="Företag"></v-select>
      </div>
      <app-button class="btn--secondary mr-3" @click="showModal = true">Lägg till fordon</app-button>
    </div>
    <div class="box box--outline mt-3">
      <app-table :head="head">
        <tr v-for="(vehicle, index) in vehicles" :key="index">
          <td class="link-cell" @click="openEditVehicle(vehicle)">{{ vehicle.licencePlate }} ({{vehicleType(vehicle.vehicleCategory)}}) </td>
          <td>{{ vehicle.brand }} {{vehicle.model}} {{vehicle.fuelType}}</td>
          <td v-if="!isCompanyAdmin">{{ vehicle.company.name }}</td>
          <td v-if="isCompanyAdmin && vehicle.user">{{ vehicle.user.firstname }}  {{vehicle.user.lastname}}</td>
          <td>{{ vehicle.boxId }}</td>
          <td>
            <span class="table-icon" v-if="vehicle.user.email">
              <app-icon class="active-icon" name="checkmark"></app-icon>
            </span>
          </td>
        </tr>
      </app-table>
    </div>
    <div class="d-flex justify-content-end mt-5">
      <app-pagination
          :current-page="currentPage"
          :total-pages="totalPages"
          @pageChanged="onPageChanged"
      >
      </app-pagination>
    </div>
  </div>
</template>

<script>
import vehiclesHttp from '@/http/vehicles'
import companiesHttp from '@/http/companies'
import AddVehicle from "@/components/vehicles/AddVehicle";
import {getTotalPages} from "@/utils/pagination";
import AppPagination from "@/components/shared/AppPagination";

export default {
  components: {AppPagination, AddVehicle},
  data() {
    return {
      head: [
        {name: 'Reg.nr'},
        {name: 'Fordon'},
        {name: 'Företag'},
        {name: 'Enheter'},
        {name: ''}
      ],
      vehicles: [],
      companies: [],
      currentPage: 1,
      totalPages: 1,
      editVehicle: null,
      showModal: false,
      search: null,
      company: null
    }
  },
  computed: {
    isCompanyAdmin() {
      return this.$store.getters.isCompanyAdmin
    }
  },
  methods: {
    async getCompanies() {
      try {
        const {companies} = await companiesHttp.getCompanies();
        this.companies = companies;
      } catch (e) {
        this.$notify.error('Error fetching companies')
      }
    },
    async getVehicles() {
      try {
        //, 10, this.currentPage, this.search, this.company
        const {vehicles, totalCount} = await vehiclesHttp.getVehicles({limit: 10, page: this.currentPage, search: this.search, company: this.company});
        this.vehicles = vehicles;

        this.totalPages = getTotalPages(totalCount)
      } catch (e) {
        this.$notify.error('Error fetching vehicles');
      }
    },
    getCompanyName(id) {
      const company = this.companies.find(item => item._id === id);
      return company ? company?.name : ''
    },
    onVehicleChanged() {
      this.getVehicles();

      this.editVehicle = null;
      this.showModal = false;
    },
    searchVehicles() {
      this.currentPage = 1;
      this.getVehicles();
    },
    onPageChanged(page) {
      this.currentPage = page;
      this.getVehicles();
    },
    openEditVehicle(vehicle) {
      this.editVehicle = vehicle;
      this.showModal = true;
    },
    vehicleType(type) {
      if(!type) return 'Företagsbil'
      return type === 'business' ? 'Företagsbil' : 'Förmånsbil'
    }
  },
  async created() {
    if(!this.isCompanyAdmin) {
      await this.getCompanies();
    }
    if(this.isCompanyAdmin) {
      this.head[2] = {name: 'Användare'}
    }
    await this.getVehicles();
  }
}
</script>

<style scoped>

</style>
