<template>
  <div class="add-vehicle">
    <h1 class="section-header">Fordonsinformation</h1>

    <validation-observer ref="vehicleForm" v-slot="{validated}">
      <div class="row mt-5">
        <validation-provider rules="required" v-slot="{invalid}" class="col-md-6">
          <input-field :disabled="!!editVehicle" :class="{'error': invalid && validated}" label="Reg.nr*" v-model="form.licencePlate"/>
        </validation-provider>
        <validation-provider rules="required" v-slot="{invalid}" class="col-md-6 select-item" v-if="!isCompanyAdmin">
          <label class="mb-2">Företag*</label>
          <v-select :disabled="!!editVehicle" :class="{'error': invalid && validated}" v-model="form.company" :reduce="item => item._id" :options="companies" label="name"></v-select>
        </validation-provider>
      </div>

      <div class="row mt-5">
        <validation-provider rules="required" v-slot="{invalid}" class="col-md-6 select-item">
          <label class="mb-2">Märke*</label>
          <v-select :class="{'error': invalid && validated}" v-model="form.brand" :options="makers"></v-select>
        </validation-provider>
        <validation-provider rules="required" v-slot="{invalid}" class="col-md-6 select-item">
          <label class="mb-2">Modell*</label>
          <v-select :class="{'error': invalid && validated}" v-model="form.model" :options="models">
            <template #no-options>Inga tillgängliga val.</template>
          </v-select>
        </validation-provider>
        <validation-provider rules="required" v-slot="{invalid}" class="col-md-6 mt-4 select-item">
          <label class="mb-2">Bränsle*</label>
          <v-select :class="{'error': invalid && validated}" v-model="form.fuelType" :options="fuelTypes"></v-select>
        </validation-provider>
      </div>

      <div class="row mt-5">
        <div class="col-md-6 select-item">
          <label class="mb-2">Enheter</label>
          <v-select v-model="form.boxId" :reduce="item => item.boxId" :options="allowedBoxes" label="boxId">
            <template #no-options>Inga tillgängliga val.</template>
          </v-select>
        </div>
        <div class="col-md-5">
          <input-field append-content="km" label="Nuvarande mätarställning" v-model="form.startMileage"/>
        </div>
      </div>
    </validation-observer>

    <div class="d-flex justify-content-end mt-5">
      <app-button v-if="editVehicle" class="btn--red mr-3" @click="deleteVehicle">Radera</app-button>
      <app-button :loading="loading" class="btn--secondary" @click="addVehicle">
        <template v-if="!editVehicle">Lägg till</template>
        <template v-else>Spara</template>
      </app-button>
    </div>
  </div>
</template>

<script>
import vehiclesHttp from '@/http/vehicles'
import boxesHttp from '@/http/boxes'
import {
  cancelButton,
  createSuccess,
  deleteDialogMessage,
  errorNotification,
  updateSuccess,
  yesButton
} from "@/utils/translations";
import {objectAssignExistingProperties} from "@/utils/object-helper";
import {makeErrorMessage} from "@/utils/error.helper";

export default {
  props: ['companies', 'isCompanyAdmin', 'editVehicle'],
  data() {
    return {
      form: {
        brand: null,
        model: null,
        fuelType: null,
        startMileage: '',
        licencePlate: '',
        company: null,
        boxId: null
      },
      fuelTypes: ['Bensin', 'Diesel', 'El', 'Hybrid'],
      boxes: [],
      loading: false,
      makers: [],
      models: []
    }
  },
  watch: {
    'form.brand': {
      immediate: true,
      async handler(make) {
        if(!make) {
          this.models = [];
          this.form.model = null;
        } else {
          await this.getModels(make);

          if(!this.models.includes(this.form.model)) {
            this.form.model = null;
          }
        }
      }
    }
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    allowedBoxes() {
      if(this.isCompanyAdmin) {
        return this.boxes.filter(item => item.company._id === this.user.company)
      }
      return this.boxes.filter(item => item.company._id === this.form.company)
    }
  },

  methods: {
    async addVehicle() {
      try {
        const isValid = await this.$refs.vehicleForm.validate();
        if(!isValid) return;

        this.loading = true;

        if(this.editVehicle) {
          await vehiclesHttp.updateVehicle(this.form);
          this.$notify.success(updateSuccess('Fordonet'))
        } else {
          await vehiclesHttp.addVehicle(this.form);
          this.$notify.success(createSuccess('Fordonet'))
        }

        this.$emit('vehicleChanged');
      } catch (e) {
        const message = makeErrorMessage(e)
        this.$notify.error(message);
      } finally {
        this.loading = false;
      }
    },
    async deleteVehicle() {
      try {
        await this.$confirm('', deleteDialogMessage, {
          confirmButtonText: yesButton,
          cancelButtonText: cancelButton,
          type: 'warning'
        })
      } catch (e) {
        return;
      }

      try {
        await vehiclesHttp.deleteVehicle(this.form.licencePlate);
        this.$notify.success('Fordonet togs bort!');

        this.$emit('vehicleChanged');
      } catch (e) {

      }
    },
    async getMakers() {
      try {
        this.makers = await vehiclesHttp.getMakers();
      } catch (e) {

      }
    },
    async getModels(make) {
      try {
        this.models = await vehiclesHttp.getModelsForMake(make);
      } catch (e) {
      }
    },
  },
  created() {
    if(this.editVehicle) {
      Object.assign(this.form, this.editVehicle);

      this.form.company = this.editVehicle.company?._id;
    }

    boxesHttp.getBoxes('unbound').then(response => {
      const {boxes} = response;
      this.boxes = boxes;
    })

    this.getMakers();

  }
}
</script>

<style lang="scss" scoped>
.add-vehicle {

}
</style>
