var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-vehicle"},[_c('h1',{staticClass:"section-header"},[_vm._v("Fordonsinformation")]),_c('validation-observer',{ref:"vehicleForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validated = ref.validated;
return [_c('div',{staticClass:"row mt-5"},[_c('validation-provider',{staticClass:"col-md-6",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('input-field',{class:{'error': invalid && validated},attrs:{"disabled":!!_vm.editVehicle,"label":"Reg.nr*"},model:{value:(_vm.form.licencePlate),callback:function ($$v) {_vm.$set(_vm.form, "licencePlate", $$v)},expression:"form.licencePlate"}})]}}],null,true)}),(!_vm.isCompanyAdmin)?_c('validation-provider',{staticClass:"col-md-6 select-item",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('label',{staticClass:"mb-2"},[_vm._v("Företag*")]),_c('v-select',{class:{'error': invalid && validated},attrs:{"disabled":!!_vm.editVehicle,"reduce":function (item) { return item._id; },"options":_vm.companies,"label":"name"},model:{value:(_vm.form.company),callback:function ($$v) {_vm.$set(_vm.form, "company", $$v)},expression:"form.company"}})]}}],null,true)}):_vm._e()],1),_c('div',{staticClass:"row mt-5"},[_c('validation-provider',{staticClass:"col-md-6 select-item",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('label',{staticClass:"mb-2"},[_vm._v("Märke*")]),_c('v-select',{class:{'error': invalid && validated},attrs:{"options":_vm.makers},model:{value:(_vm.form.brand),callback:function ($$v) {_vm.$set(_vm.form, "brand", $$v)},expression:"form.brand"}})]}}],null,true)}),_c('validation-provider',{staticClass:"col-md-6 select-item",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('label',{staticClass:"mb-2"},[_vm._v("Modell*")]),_c('v-select',{class:{'error': invalid && validated},attrs:{"options":_vm.models},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v("Inga tillgängliga val.")]},proxy:true}],null,true),model:{value:(_vm.form.model),callback:function ($$v) {_vm.$set(_vm.form, "model", $$v)},expression:"form.model"}})]}}],null,true)}),_c('validation-provider',{staticClass:"col-md-6 mt-4 select-item",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('label',{staticClass:"mb-2"},[_vm._v("Bränsle*")]),_c('v-select',{class:{'error': invalid && validated},attrs:{"options":_vm.fuelTypes},model:{value:(_vm.form.fuelType),callback:function ($$v) {_vm.$set(_vm.form, "fuelType", $$v)},expression:"form.fuelType"}})]}}],null,true)})],1),_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-md-6 select-item"},[_c('label',{staticClass:"mb-2"},[_vm._v("Enheter")]),_c('v-select',{attrs:{"reduce":function (item) { return item.boxId; },"options":_vm.allowedBoxes,"label":"boxId"},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v("Inga tillgängliga val.")]},proxy:true}],null,true),model:{value:(_vm.form.boxId),callback:function ($$v) {_vm.$set(_vm.form, "boxId", $$v)},expression:"form.boxId"}})],1),_c('div',{staticClass:"col-md-5"},[_c('input-field',{attrs:{"append-content":"km","label":"Nuvarande mätarställning"},model:{value:(_vm.form.startMileage),callback:function ($$v) {_vm.$set(_vm.form, "startMileage", $$v)},expression:"form.startMileage"}})],1)])]}}])}),_c('div',{staticClass:"d-flex justify-content-end mt-5"},[(_vm.editVehicle)?_c('app-button',{staticClass:"btn--red mr-3",on:{"click":_vm.deleteVehicle}},[_vm._v("Radera")]):_vm._e(),_c('app-button',{staticClass:"btn--secondary",attrs:{"loading":_vm.loading},on:{"click":_vm.addVehicle}},[(!_vm.editVehicle)?[_vm._v("Lägg till")]:[_vm._v("Spara")]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }